import swal from "sweetalert";
import api, { logout } from "../../constants/api";
import { getToken } from "../../reducers/userReducers";
import store from "../../store";
import showLoadingExport from "../alert/show_alert";
import exportFromJSON from "export-from-json";
import { convertFromRaw, convertToRaw, EditorState } from "draft-js";

export default function exportReducer(
  state = {
    isLoaded: false,
    data: {
      total: 0,
      exported: 0,
    },
  },
  action
) {
  switch (action.type) {
    case RESET_EXPORT:
      return (state = {
        data: {
          total: 0,
          exported: 0,
        },
      });
    case SET_EXPORT: {
      return {
        total: action.total,
        exported: action.exported,
      };
    }

    default:
      return state;
  }
}

let data = [];

export async function getDataFirstFunction(
  with_visits,
  relations,
  fileName,
  medical_history,
  treatment,
  systemReview,
  currentLlenuss,
  cvs,
  visit_images,
  report,
  invistigation,
  examination,
  from,
  to
) {
  data = [];
  startGetData(
    with_visits,
    relations,
    fileName,
    medical_history,
    treatment,
    systemReview,
    currentLlenuss,
    cvs,
    visit_images,
    report,
    invistigation,
    examination,
    from,
    to
  );
}

export async function startGetData(
  with_visits,
  relations,
  fileName,
  medical_history,
  treatment,
  systemReview,
  currentLlenuss,
  cvs,
  visit_images,
  report,
  invistigation,
  examination,
  from,
  to
) {
  showLoadingExport();
  let newData = await getAllVisitsData(with_visits, relations, from, to);

  data = data.concat(newData.patients);
  parseData(
    data,
    fileName,
    with_visits,
    medical_history,
    treatment,
    systemReview,
    currentLlenuss,
    cvs,
    visit_images,
    report,
    invistigation,
    examination
  );
}

export async function getAllVisitsData(with_visits, relations, from, to) {
  const config = {
    headers: { Authorization: `Bearer ${getToken()}` },
  };

  store.dispatch({ type: RESET_EXPORT, payload: false });
  try {
    const response = await api.get(
      `clinic-reports/get-patient-info?with_visits=${with_visits}&relations=${relations}&from=${from}&to=${to}`,
      config
    );

    if (
      document.getElementsByClassName("swal-text")[0] != null &&
      document.getElementsByClassName("swal-text")[0]
    ) {
      const tot = response.data.total;
      document.getElementsByClassName("swal-text")[0].innerHTML =
        response.data.patients.length + " from " + tot;
    }
    const data = response.data;

    return data;
  } catch (error) {
    await swal("Oops!", "Failed to export patient data", "error");

    if (error.response?.status === 401) {
      logout();
    }
  }
}

async function parseData(
  rawData,
  fileNameParam,
  with_visits,
  medical_history,
  treatmentInclude,
  systemReview,
  currentLlenuss,
  cvs,
  visit_images,
  report,
  invistigation,
  examination
) {
  try {
    let data = [];
    rawData.forEach((personElement) => {
      let currentData = {};
      currentData = {
        id: personElement.id,
        code: personElement.code,
        fullname: personElement.fullname ?? "",
        Visits: personElement.visits ?? "",
        phone: personElement.phone ?? "",
        birthdate: personElement.birthdate ?? "",
        gender: personElement.gender ?? "",
        blood_type: personElement.blood_type ?? "",
        address: personElement.info?.address ?? "",
        marital_status: personElement.info?.marital_status ?? "",
        alkohol: personElement.info?.alkohol ?? "",
        smoking: personElement.info?.smoking ?? "",
        doctor: personElement.info?.doctor?.name ?? "",
        occupation: personElement.info?.occupation?.name ?? "",
      };
      if (cvs == true) {
        let images = "";
        personElement.cvs.forEach((image) => {
          images = images + (image.url ?? "");
        });
        currentData["cvs"] = images;
      }
      if (medical_history == true) {
        personElement.medical_history.forEach((element) => {
          currentData[element.disease + " Type"] = element.note;
          currentData[element.disease + " Range"] = element.range;
        });
      }
      data.push(currentData);

      if (with_visits == true) {
        personElement.all_visits.forEach((visit) => {
          currentData = {};
          currentData["visits"] = visit.visits;
          currentData["state"] = visit.state;
          // currentData["visit_id"] = visit.id;
          currentData["price"] = visit.price;
          currentData["created_at"] = visit.created_at;

          if (currentLlenuss == true) {
            visit?.currentLlenuss.forEach((currentLlenus) => {
              currentData[currentLlenus.type] = currentLlenus.note;
            });
          }

          if (systemReview) {
            visit?.systemReview.forEach((systemReviews) => {
              currentData[systemReviews.type] = systemReviews.note;
            });
          }
          if (examination) {
            visit?.examination.forEach((examinationVal) => {
              currentData[examinationVal.type] = examinationVal.result;
            });
          }
          if (treatmentInclude == true) {
            let treatment = "";
            visit?.treatment.forEach((treatments) => {
              treatment =
                treatment +
                (treatments.drug?.name ?? "") +
                " " +
                (treatment.note ?? "");
            });
            currentData["treatment"] = treatment;
          }
          if (visit_images == true) {
            let images = "";
            visit?.images.forEach((image) => {
              images = images + (image.url ?? "");
            });
            currentData["visit_images"] = images;
          }
          if (invistigation == true) {
            let invistigations = "";
            visit?.invistigation.forEach((temp) => {
              invistigations =
                invistigations + (temp.test?.name + `(${temp.result_number})-`);
            });
            currentData["invistigation"] = invistigations;
          }

          if (
            report == true &&
            visit.dischargReport?.note != "" &&
            visit.dischargReport?.note != null &&
            visit.dischargReport?.note != undefined
          ) {
            const content = EditorState.createWithContent(
              convertFromRaw(JSON.parse(JSON.parse(visit.dischargReport?.note)))
            );

            let toStr = content.getCurrentContent().getPlainText();

            currentData["report"] = toStr ?? "";
          }

          data.push(currentData);
        });
      }
    });
    const fileName = fileNameParam;
    const exportType = exportFromJSON.types.csv;

    exportFromJSON({ data, fileName, exportType, withBOM: true });

    swal("Good job!", "Patient data was exported successfully", "success");
  } catch (error) {
    console.log(error);
    await swal("Oops!", "Failed to export patient data", "error");
  }
}

export const SET_EXPORT = "SET_EXPORT";
export const RESET_EXPORT = "RESET_EXPORT";
