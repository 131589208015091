import swal from "sweetalert";
import { getToken } from "../../../../reducers/userReducers";
import api, { logout } from "../../../../constants/api";
import store from "../../../../store";

export async function saveExamination(types, results, visit_id) {
  const config = {
    headers: { Authorization: `Bearer ${getToken()}` },
  };
  try {
    const response = await api.post(
      "examinations/create",
      {
        visit_id: visit_id,
        type: types,
        result: results,
      },
      config
    );

    const data = response.data;
    store.dispatch({
      type: SET_EXAMINATIONS,
      payload: { data: data, id: visit_id },
    });

    await swal("Good job!", "Examination was saved successfully", "success");

    return true;
  } catch (error) {
    if (error.response?.status === 401) {
      logout();
    } else if (error.response?.status === 422) {
      await swal("Opps!", "The given data was invalid", "error");
      return false;
    } else {
      await swal("Opps!", "Failed to save Examination", "error");
    }
    return false;
  }
}

export async function updateExamination(types, results, visit_id, ids) {
  const config = {
    headers: { Authorization: `Bearer ${getToken()}` },
  };
  try {
    const response = await api.put(
      "examinations/update",
      {
        visit_id: visit_id,
        type: types,
        result: results,
        id: ids,
      },
      config
    );

    const data = response.data;
    store.dispatch({
      type: SET_EXAMINATIONS,
      payload: { data: data, id: visit_id },
    });
    await swal("Good job!", "Examination was saved successfully", "success");

    return true;
  } catch (error) {
    if (error.response?.status === 401) {
      logout();
    } else if (error.response?.status === 422) {
      await swal("Opps!", "The given data was invalid", "error");
      return false;
    } else {
      await swal("Opps!", "Failed to save Examination", "error");
    }
    return false;
  }
}
export const SET_EXAMINATIONS = "SET_EXAMINATIONS";
