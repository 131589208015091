import React, { Component } from "react";
import PersonalInfo from "../PersonalInfo";
import "../printStyle.css";
import PersonalInfoPrint from "../printTremnats/personalInFo";

class PageTrmenats extends Component {
  constructor(props) {
    console.log("propsprops",props);
    super(props);
  }

  render() {
    let array = [];

    this.props.state?.forEach((element, index) => {
      array.push(
        <tr key={index}>
          <td style={{ "font-size": "25px" }}>
            <div>{index + 1}</div>
          </td>
          <td style={{ "font-size": "25px" }}>
            <div>{element.drug.name}</div>
          </td>
          <td style={{ "font-size": "25px" }}>
            <div>{element.note}</div>
          </td>
        </tr>
      );
    });

    return (
      <div className=" page" style={{ "font-size": "22px", width: "16.2cm" }}>
        {/* <div className="bg-primary border rounded px-1 py-1 d-flex justify-content-between">
          <h6 className="text-bold">Name: Name</h6>
          <h6 className="text-bold">Gender: Male</h6>
          <h6 className="text-bold">Age: 45</h6>
          <h6 className="text-bold">Date</h6>
        </div> */}
        <table className="table table-bordered">
          <thead>
            <PersonalInfoPrint patinet={this.props.patinet} />
            <tr>
              <th
                className="text-white p-0"
                style={{ width: "5%", "font-size": "25px" }}
              >
                <div className="bg-success m-0 p-2">#</div>
              </th>
              <th
                className="text-white p-0"
                style={{ width: "40%", "font-size": "25px" }}
              >
                <div className="bg-success m-0 p-2">Drug</div>
              </th>
              <th
                className="bg-success p-0"
                style={{ width: "55%", "font-size": "25px" }}
              >
                <div className="bg-success m-0 p-2">Note</div>
              </th>
            </tr>
          </thead>
          <tbody>{array}</tbody>
        </table>
      </div>
    );
  }
}

export default PageTrmenats;
