import React, { useState } from "react";
import {
  getDataFirstFunction
} from "./reportReducer";
import swal from "sweetalert";

function ExportScreen() {
  const [state, setState] = useState({
    with_visits: false,
    medical_history: false,
    treatment: false,
    systemReview: false,
    currentLlenuss: false,
    cvs: false,
    visit_images: false,
    report: false,
    examination: false,
    invistigation: false,
    fileName: "",
    dateFrom: "",
    dateTo: ""
  });
  const [error,setError]=useState(null)
  const handleExport = () => {
    const { dateFrom, dateTo, fileName } = state;

    if (!dateFrom || !dateTo) {
      setError("Both Date From && Date To Are Required")
      return;
    }


    setError('')
    getDataFirstFunction(
      state.with_visits || state.examination,
      state.medical_history || state.cvs,
      state.fileName,
      state.medical_history,
      state.treatment,
      state.systemReview,
      state.currentLlenuss,
      state.cvs,
      state.visit_images,
      state.report,
      state.invistigation,
      state.examination,
      state.dateFrom,
      state.dateTo
    );
  };

  return (
    <div className="card rounded-top mr-2">
      <div className="card-header bg-info">Report</div>
      <div className="card-body">
        <div className="jumbotron">
          <input
            placeholder="File Name"
            value={state.fileName}
            onChange={(e) => {
              setState({
                ...state,
                fileName: e.target.value,
              });
            }}
          />

          <div className="mt-2">
            <label>Date From: </label>
            <input
              type="date"
              value={state.dateFrom}
              onChange={(e) => {
                setState({
                  ...state,
                  dateFrom: e.target.value,
                });
              }}
            />
          </div>
          
          <div className="mt-2">
            <label>Date To: </label>
            <input
              type="date"
              value={state.dateTo}
              onChange={(e) => {
                setState({
                  ...state,
                  dateTo: e.target.value,
                });
              }}
            />
          </div>
          <p className="text-red">{error && error} </p>

          <div className="checkbox mt-2">
            <label>
              <input
                type="checkbox"
                checked={state.with_visits}
                onChange={(e) => {
                  setState({
                    ...state,
                    with_visits: !state.with_visits,
                    treatment: !state.with_visits,
                    systemReview: !state.with_visits,
                    currentLlenuss: !state.with_visits,
                    examination: !state.with_visits,
                    visit_images: !state.with_visits,
                    report: !state.with_visits,
                  });
                }}
              />
              with_visits
            </label>
          </div>

          {/* Other checkboxes */}
          <div className="checkbox">
            <label>
              <input
                type="checkbox"
                checked={state.medical_history}
                onChange={(e) => {
                  setState({
                    ...state,
                    medical_history: !state.medical_history,
                  });
                }}
              />
              medical history
            </label>
          </div>
          
          <div className="checkbox">
            <label>
              <input
                type="checkbox"
                checked={state.systemReview}
                onChange={(e) => {
                  setState({
                    ...state,
                    systemReview: !state.systemReview,
                    with_visits: state.report === false ? true : state.systemReview,
                  });
                }}
              />
              System Review
            </label>
          </div>
          
          <div className="checkbox">
            <label>
              <input
                type="checkbox"
                checked={state.treatment}
                onChange={(e) => {
                  setState({
                    ...state,
                    treatment: !state.treatment,
                    with_visits: state.report === false ? true : state.treatment,
                  });
                }}
              />
              treatments
            </label>
          </div>
          
          <div className="checkbox">
            <label>
              <input
                type="checkbox"
                checked={state.examination}
                onChange={(e) => {
                  setState({
                    ...state,
                    examination: !state.examination,
                    with_visits: state.report === false ? true : state.examination,
                  });
                }}
              />
              examinations
            </label>
          </div>
          
          <div className="checkbox">
            <label>
              <input
                type="checkbox"
                checked={state.currentLlenuss}
                onChange={(e) => {
                  setState({
                    ...state,
                    currentLlenuss: !state.currentLlenuss,
                    with_visits: state.report === false ? true : state.currentLlenuss,
                  });
                }}
              />
              HISTORY OF CURRENT ILLNESS
            </label>
          </div>
          
          <div className="checkbox">
            <label>
              <input
                type="checkbox"
                checked={state.cvs}
                onChange={(e) => {
                  setState({
                    ...state,
                    cvs: !state.cvs,
                  });
                }}
              />
              cvs
            </label>
          </div>
          
          <div className="checkbox">
            <label>
              <input
                type="checkbox"
                checked={state.visit_images}
                onChange={(e) => {
                  setState({
                    ...state,
                    visit_images: !state.visit_images,
                    with_visits: state.report === false ? true : state.visit_images,
                  });
                }}
              />
              visit_images
            </label>
          </div>
          
          <div className="checkbox">
            <label>
              <input
                type="checkbox"
                checked={state.report}
                onChange={(e) => {
                  setState({
                    ...state,
                    report: !state.report,
                    with_visits: state.report === false ? true : state.with_visits,
                  });
                }}
              />
              report
            </label>
          </div>
          
          <div className="checkbox">
            <label>
              <input
                type="checkbox"
                checked={state.invistigation}
                onChange={(e) => {
                  setState({
                    ...state,
                    invistigation: !state.invistigation,
                    with_visits: state.invistigation === false ? true : state.with_visits,
                  });
                }}
              />
              invistigation
            </label>
          </div>

          <button
            className="btn btn-primary mt-2"
            onClick={handleExport}
          >
            Export
          </button>
        </div>
      </div>
    </div>
  );
}

export default ExportScreen;
