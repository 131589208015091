import React, { Component } from "react";
import PersonalInfo from "../PersonalInfo";
import "../printStyle.css";
import PageOfInvistigation from "./Invistigation_page";
import PageTrmenats from "../printresult/page";
import ReportPage from "../printreport/report_page";

class FirstPrintPage extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const patinet = this.props.state;
    console.log("propsprops", patinet.patient);
    return (
      <div className="A5 body">
        <div className="page-header">
          <div id="headerImg"></div>
          {/* <div className="bg-primary border rounded px-1 py-1 d-flex justify-content-between">
            <h6 className="text-bold">Name: {patinet.patient.fullname}</h6>
            <h6 className="text-bold">Gender: {patinet.patient.gender}</h6>
            <h6 className="text-bold">
              Age: {getAge(patinet.patient.birthdate)}
            </h6>
            <h6 className="text-bold">
              Date:{patinet.created_at.split(" ")[0]}
            </h6>
          </div> */}
        </div>

        <div className="page-footer">
          <div id="footerImg"></div>
        </div>

        <table>
          <thead>
            <tr>
              <td>
                <div className="page-header-space"></div>
              </td>
            </tr>
          </thead>

          <tbody>
            <tr>
              <td>
                {this.props.type === "invistigation" ? (
                  <PageOfInvistigation
                    state={this.props.state.invistigation}
                    patinet={patinet}
                  />
                ) : this.props.type === "report" ? (
                  <ReportPage state={this.props.report} patinet={patinet} />
                ) : (
                  <PageTrmenats
                    state={this.props.treatment}
                    patinet={patinet}
                  />
                )}
              </td>
            </tr>
          </tbody>
{/* 
          <tfoot>
            <tr>
              <td>
                <div className="page-footer-space"></div>
              </td>
            </tr>
          </tfoot> */}
        </table>
      </div>
    );
  }
}

//

export default FirstPrintPage;
const getAge = (dateString) => {
  var nowDate = new Date(new Date().setHours(0, 0, 0, 0));
  // Example date of birth.
  var dobDate = new Date(dateString);

  var years = nowDate.getFullYear() - dobDate.getFullYear();
  var months = nowDate.getMonth() - dobDate.getMonth();
  var days = nowDate.getDate() - dobDate.getDate();

  // Work out the difference in months.
  months += years * 12;
  if (days < 0) {
    months -= 1;
  }
  // Now add those months to the date of birth.
  dobDate.setMonth(dobDate.getMonth() + months);
  // Calculate the difference in milliseconds.
  let diff = nowDate - dobDate;
  // Divide that by 86400 to get the number of days.
  var days = Math.round(diff / 86400 / 1000);
  // Now convert months back to years and months.
  years = parseInt(months / 12);
  months -= years * 12;

  let text = "";

  if (years) {
    return years + (years > 1 ? " years" : " year");
  }
  if (months) {
    if (text.length) {
      text = text + ", ";
    }
    return months + (months > 1 ? " months" : " month");
  }
  if (days) {
    if (text.length) {
      text = text + ", ";
    }
    return days + (days > 1 ? " days" : " day");
  }

  if (
    nowDate.toISOString().split("T")[0] === dobDate.toISOString().split("T")[0]
  ) {
    return "Newborn";
  }
  if (
    text === "" ||
    text === null ||
    text === undefined ||
    days < 0 ||
    months < 0 ||
    years < 0
  ) {
    text = "age can not be calculated";
  }
  return text;
};
