import React, { useState } from "react";
import Form from "react-bootstrap/Form";
import swal from "sweetalert";
import api from "../../../../constants/api";
import { getToken } from "../../../../reducers/userReducers";
import store from "../../../../store";
import pdfPlaceHolder from "./pdf.jpg";
const VisitionImage = (props) => {
  const [fileList, setFileList] = useState([]);
  const [selectedFiles, SetSelectedFile] = useState([]);
  let isComplete = props.state.state == "completed";

  let files = [];
  let images = [];
  const [disable, setDisble] = useState(false);

  props.state.images?.forEach((e) => {
    images.push(
      <tr>
        <td>
          <a href={e.url} target="_blank">
            {e.url.toString().toLowerCase().endsWith("pdf") == false ? (
              <img width="100" height="100" src={e.url} />
            ) : (
              <img width="100" height="100" src={pdfPlaceHolder} />
            )}
          </a>
          {e.created_at?.toString().split(" ")[0] ?? ""}
        </td>
        <td>
          <button
            className="btn btn-danger"
            disabled={isComplete}
            onClick={(ee) => {
              swal({
                title: "Are you sure?",
                text: "Once deleted, you will not be able to recover this image",
                icon: "warning",
                buttons: true,
                dangerMode: true,
              }).then((willDelete) => {
                if (willDelete) {
                  deleteImage(e.id);
                } else {
                }
              });
            }}
          >
            <i className="fas fa-trash text-white"></i>
          </button>
        </td>
      </tr>
    );
  });
  fileList.forEach((e) => {
    files.push(
      <tr key={Math.floor(Math.random() * 435611)}>
        <td>
          <a href={e.image} target="_blank">
            {e.type.toString().toLowerCase().endsWith("pdf") == false ? (
              <img width="100" height="100" id="target" src={e.image} />
            ) : (
              <img width="100" height="100" src={pdfPlaceHolder} />
            )}
          </a>
        </td>
        <td>
          <button className="btn btn-danger">
            <i className="fas fa-trash text-white"></i>
          </button>
        </td>
      </tr>
    );
  });
  const reset = () => {
    setFileList([]);
  };
  return (
    <section>
      <div className="card">
        <div className="card-header bg-info">
          <h4 className="text-uppercase d-inline-block">
            Upload Visitation Image{" "}
          </h4>
        </div>
        <div className="card-body ">
          <form
            onSubmit={async (event) => {
              event.preventDefault();
              var imagefile = document.getElementById(
                "exampleFormControlFile1"
              );

              if (selectedFiles.length > 0) {
                setDisble(true);
                await upLoadImages(selectedFiles, props.state.id, reset);
                setDisble(false);
              } else {
              }
            }}
          >
            <Form.Group>
              <div className="row text-center ">
                <div className="col-8">
                  <Form.File
                    disabled={isComplete}
                    id="exampleFormControlFile1"
                    accept="image/*"
                    onChange={(event) => {
                      let temp = [];
                      Array.from(event.target.files).forEach((e) => {
                        temp.push({
                          type: e.type,
                          image: URL.createObjectURL(e),
                        });
                      });

                      setFileList([...temp]);
                      SetSelectedFile([...event.target.files]);
                    }}
                    multiple
                    name="file"
                    className="form-control"
                  />
                </div>
                <div className="col-4">
                  <button
                    className="btn btn-primary"
                    disabled={isComplete || disable}
                    type="submit"
                  >
                    {" "}
                    Submit
                  </button>
                </div>
              </div>
            </Form.Group>
          </form>
          <table className="table table-bordered">
            <thead className="bg-info">
              <tr>
                <th>Image</th>
                <th>Delete</th>
              </tr>
            </thead>
            {files.length == 0 ? <div></div> : <tbody>{files}</tbody>}
            <tbody>{images}</tbody>
          </table>
        </div>
      </div>
    </section>
  );
};

export default VisitionImage;

const upLoadImages = async (files, visit_id, reset) => {
  try {
    let config = {
      headers: {
        "Content-Type": "multipart/form-data",
        Accept: "application/json",
        Authorization: `Bearer  ${getToken()}`,
      },
    };

    var formData = new FormData();

    files.forEach((e, index) => {
      formData.append("img" + index, files[index]);
    });

    formData.append("visit_id", visit_id);

    const { data } = await api.post("visits/upload-images", formData, config);

    store.dispatch({
      type: ADD_IMAGE,
      payload: { visit_id: visit_id, images: data },
    });
    reset();
    swal("Good job!", "Images Was Uploaded successfully", "success");
  } catch (error) {
    swal("Opps!", "Failed to upload images", "error");
  }
};

const deleteImage = async (id) => {
  try {
    let config = {
      headers: {
        "Content-Type": "multipart/form-data",
        Accept: "application/json",
        Authorization: `Bearer  ${getToken()}`,
      },
    };

    const { data } = await api.delete(
      "visits/delete-visit-image/" + id,

      config
    );

    store.dispatch({ type: DELETE_IMAGE, payload: id });
    swal("Good job!", "Images Was Deleted successfully", "success");
  } catch (error) {
    swal("Opps!", "Failed to deleted images", "error");
  }
};
export const DELETE_IMAGE = "DELETE_IMAGE";
export const ADD_IMAGE = "ADD_IMAGE";

export const ADD_CVS = "ADD_CVS";
