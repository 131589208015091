import React, { useState } from "react";
import HistoryOfCurrentIlliness from "./HistoryOfCurrentIllin/HistoryOfCurrentIliness";
import ExaminationSection from "./ExaminationSection/ExaminationSection";
import Treatments from "./TreatmentsSection/Treatments";
import InvestigaitonSection from "./Investigation/InvestigationSection";
import VisitionImage from "./VisitationImageSection/VisitationImage";
import LastReportSection from "./LastReportSection/LastReportSection";
import swal from "sweetalert";
import {
  deleteVisit,
  updateCompletesitPrice,
  updateVisitPrice,
} from "./create_visit_reducer";
import SystemRivew from "./dynamicfiled/SystemRivew";
import { useSelector } from "react-redux";

const DoctorVisitForm = (props) => {
  const [disable, setDisble] = useState(false);

  let price = props.state.price;
  const userinfo = useSelector((state) => state.userLogin);
  const { userInfo } = userinfo;
  let isAdmni = userInfo.user_info.user_type == "lab-admin";
  return (
    <div>
      <div className="border-warning mb-2 text-center d-flex justify-content-between">
        <button
          disabled={disable}
          className="btn btn-outline-warning"
          onClick={async (e) => {
            setDisble(true);
            await updateCompletesitPrice(
              props.state.patient.id,
              props.state.price,
              props.state.id,
              props.state.state != "completed" ? "completed" : "not compledted"
            );
            setDisble(false);
          }}
        >
          {props.state.state == "completed" ? "Edit" : "Complete"}
        </button>
        <div>{props.state.created_at}</div>
        <button
          className="btn btn-outline-danger"
          onClick={(e) => {
            swal({
              title: "Are you sure to delete visit?",
              text: "Once deleted, you will not be able to recover",
              icon: "warning",
              buttons: true,
              dangerMode: true,
            }).then((willDelete) => {
              if (willDelete) {
                deleteVisit(props.state.id);
              } else {
              }
            });
          }}
        >
          Delete
        </button>
        <form
          onSubmit={async (e) => {
            e.preventDefault();
            setDisble(true);
            await updateCompletesitPrice(
              props.state.patient.id,
              price,
              props.state.id,
              props.state.state
            );
            setDisble(false);
          }}
        >
          <label>Visit Cost</label>
          <input
            type="number"
            defaultValue={props.state.price}
            className="form-control ml-2 mr-2 d-inline-block w-50"
            id="cost"
            onChange={(e) => {
              price = e.target.value;
            }}
            placeholder="Price"
          />
          <button
            className="btn btn-outline-success"
            type="submit"
            disabled={disable}
          >
            Save
          </button>
        </form>
      </div>
      {isAdmni == false ? null : (
        <section>
          <HistoryOfCurrentIlliness state={props.state} />
        </section>
      )}
      {isAdmni == false ? null : (
        <section>
          <SystemRivew state={props.state} />
        </section>
      )}
      <section>
        <ExaminationSection state={props.state} />
      </section>
      {isAdmni == false ? null : <Treatments state={props.state} />}
      {isAdmni == false ? null : <InvestigaitonSection state={props.state} />}
      <VisitionImage state={props.state} />
      {isAdmni == false ? null : <LastReportSection state={props.state} />}
    </div>
  );
};

export default DoctorVisitForm;
