import React, { useEffect, useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import DatePicker from "react-datepicker";
import { Form as FormBot } from "react-bootstrap";
import { saveExamination, updateExamination } from "./examination_operation";
import { useSelector } from "react-redux";

const ExaminationSection = (props) => {
  let isComplete = props.state.state == "completed";

  const [state, setState] = useState({});
  let ex = props.state?.examination;
  const [disable, setDisble] = useState(false);

  useEffect(() => {
    let temp = {};
    if (ex.length > 0) {
      ex?.forEach((e) => {
        temp[e.type] = e.result;
      });

      setState({ ...temp });
    } else {
      setState({
        spo2: 0,
        HR: 0,
        Temperature: 0,
        bplSystolic: 0,
        bplDisatolic: 0,
        bprSystolic: 0,
        bprDisatolic: 0,
        tsh: 0,
        t3: 0,
        height: 0,
        weight: 0,
        BMI: 0,
      });
    }
  }, []);

  const calculateBMI = (height, wight) => {
    let heightSquared = ((height / 100) * height) / 100;
    let bmi = round(wight / heightSquared, 1);

    return bmi;
  };
  const round = (value, precision) => {
    var multiplier = Math.pow(10, precision || 0);
    return Math.round(value * multiplier) / multiplier;
  };
  const onSubmit = async (e) => {
    e.preventDefault();

    let types = [];
    let result = [];
    Object.entries(state).forEach(([key, value]) => {
      if (
        value.toString() != "" &&
        value.toString() != undefined &&
        value.toString() != null
      ) {
        types.push(key.toString());
        result.push(value.toString());
      }
    });

    let ids = [];
    setDisble(true);

    if (ex !== null && ex !== undefined && ex.length > 0) {
      let ids = [];
      ex.forEach((e) => {
        ids.push(e.id);
      });

      await updateExamination(types, result, props.state.id, ids);
      setDisble(false);
    } else {
      await saveExamination(types, result, props.state.id);
      setDisble(false);
    }
  };
  const userinfo = useSelector((state) => state.userLogin);
  const { userInfo } = userinfo;

  let isAdmni = userInfo.user_info.user_type == "lab-admin";

  return (
    <div>
      <form onSubmit={onSubmit}>
        <div>
          <div className="card">
            <div className="card-header bg-info">
              <h4 className="text-uppercase d-inline-block">Examination </h4>
            </div>
            <div className="card-body m-auto">
              {isAdmni == false ? null : (
                <div>
                  <div className="row">
                    <div className="form-group mr-2 ml-2">
                      <div className="mb-2">
                        <label htmlFor="spo2">spo2</label>
                        <input
                          disabled={isComplete}
                          placeholder="spo2"
                          type="number"
                          step="any"
                          value={state.spo2}
                          onChange={(e) => {
                            setState({ ...state, spo2: e.target.value });
                          }}
                          className={`form-control shadow-none  ml-1 mr-1`}
                        />
                      </div>

                      <label htmlFor="HR">HR</label>
                      <input
                        placeholder="HR"
                        disabled={isComplete}
                        type="number"
                        step="any"
                        value={state.HR}
                        onChange={(e) => {
                          setState({ ...state, HR: e.target.value });
                        }}
                        className={`form-control shadow-none  ml-1 mr-1`}
                      />
                    </div>

                    <div className="form-group mr-2 ml-2">
                      <label htmlFor="Temperature">Temperature</label>
                      <input
                        placeholder="Temperature"
                        type="number"
                        step="any"
                        disabled={isComplete}
                        value={state.Temperature}
                        onChange={(e) => {
                          setState({ ...state, Temperature: e.target.value });
                        }}
                        className={`form-control shadow-none  ml-1 mr-1`}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="form-group mr-2 ml-2">
                      <label htmlFor="CRP">CRP</label>
                      <input
                        placeholder="CRP"
                        type="number"
                        step="any"
                        disabled={isComplete}
                        value={state.bplSystolic}
                        onChange={(e) => {
                          setState({ ...state, bplSystolic: e.target.value });
                        }}
                        className={`form-control shadow-none  ml-1 mr-1`}
                      />

                      <label htmlFor="Creatine">Creatine</label>
                      <input
                        disabled={isComplete}
                        placeholder="Creatine"
                        type="number"
                        step="any"
                        value={state.bplDisatolic}
                        onChange={(e) => {
                          setState({ ...state, bplDisatolic: e.target.value });
                        }}
                        className={`form-control shadow-none  ml-1 mr-1`}
                      />
                    </div>
                    <div className="form-group mr-2 ml-2">
                      <label htmlFor="Urea">Urea</label>
                      <input
                        placeholder="Urea"
                        type="number"
                        step="any"
                        disabled={isComplete}
                        value={state.bprSystolic}
                        onChange={(e) => {
                          setState({ ...state, bprSystolic: e.target.value });
                        }}
                        className={`form-control shadow-none  ml-1 mr-1`}
                      />
                      <label htmlFor="PSA">PSA</label>
                      <input
                        disabled={isComplete}
                        placeholder="PSA"
                        type="number"
                        step="any"
                        value={state.bprDisatolic}
                        onChange={(e) => {
                          setState({ ...state, bprDisatolic: e.target.value });
                        }}
                        className={`form-control shadow-none  ml-1 mr-1`}
                      />
                    </div>
                  </div>

                  <div className="row">
                    <div className="form-group mr-2 ml-2">
                      <label htmlFor="PVRU">PVRU</label>
                      <input
                        disabled={isComplete}
                        placeholder="PVRU"
                        type="number"
                        value={state.tsh}
                        onChange={(e) => {
                          setState({ ...state, tsh: e.target.value });
                        }}
                        className={`form-control shadow-none  ml-1 mr-1`}
                      />
                    </div>
                    <div className="form-group mr-2 ml-2">
                      <label htmlFor="Prostate Size">Prostate Size</label>
                      <input
                        disabled={isComplete}
                        placeholder="Prostate Size"
                        type="number"
                        step="any"
                        value={state.t3}
                        onChange={(e) => {
                          setState({ ...state, t3: e.target.value });
                        }}
                        className={`form-control shadow-none  ml-1 mr-1`}
                      />
                    </div>
                  </div>

                  <hr />
                </div>
              )}

              <div className="row">
                <div className="form-group mr-2 ml-2">
                  <label htmlFor="height">height</label>
                  <input
                    disabled={isComplete}
                    placeholder="height"
                    type="number"
                    step="any"
                    value={state.height}
                    onChange={(e) => {
                      setState({
                        ...state,
                        height: e.target.value,
                        BMI: calculateBMI(e.target.value, state.weight).toFixed(
                          1
                        ),
                      });
                    }}
                    className={`form-control shadow-none  ml-1 mr-1`}
                  />
                </div>
                <div className="form-group mr-2 ml-2">
                  <label htmlFor="weight">weight</label>
                  <input
                    placeholder="weight"
                    type="number"
                    step="any"
                    disabled={isComplete}
                    value={state.weight}
                    onChange={(e) => {
                      setState({
                        ...state,
                        weight: e.target.value,
                        BMI: calculateBMI(state.height, e.target.value).toFixed(
                          1
                        ),
                      });
                    }}
                    className={`form-control shadow-none  ml-1 mr-1`}
                  />
                </div>
                <div className="form-group mr-2 ml-2">
                  <label htmlFor="BMI">BMI </label>
                  <input
                    placeholder="BMI"
                    type="number"
                    step="any"
                    disabled
                    value={state.BMI}
                    onChange={(e) => {
                      setState({ ...state, BMI: e.target.value });
                    }}
                    className={`form-control shadow-none  ml-1 mr-1`}
                  />
                </div>
              </div>

              <hr />

              {/*stop from here examination*/}

              <button
                className="btn btn-success w-100 mt-4"
                type="submit"
                disabled={isComplete || disable}
              >
                Sumbit
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};
export default ExaminationSection;
