import React, { useEffect, useState } from "react";
import Form from "react-bootstrap/Form";
import {
  saveMedicalHistory,
  updateMedicalHistory,
} from "../EachVisition/create_visit_reducer";

const HestoryVisit = (props) => {
  const sizeYes = {
    width: "15%",
    minWidth: "100px",
  };
  const sizeDrug = {
    width: "60%",
  };
  const [state, setState] = useState();
  let ex = props.state;
  let patient_id = props.patient_id;
  const [disable, setDisble] = useState(false);

  useEffect(() => {
    let temp = {};
    if (ex.length > 0) {
      ex?.forEach((e) => {
        temp[e.type + "Range"] = e.range;
        temp[e.disease] = e.note;
      });
      setState({ ...temp });
    } else {
      setState({
        surgicalHistory: "",
        familyHistory: "",
        Hospitalization: "",
        Hypertension: "",
        DM: "",
        Thyroid: "",
        Heart: "",
        Asthma: "",
        Allergy: "",
        Cigarette: "",
        DrinkingAlcohol: "",
        Gravida: "",
        Pariety: "",
        Abortion: "",
        Dead: "",
        LengthofPregnancy: "",
        modeOfDelivery: "",
        aboutChildren: "",
        other: "",
        bloodTransfusion: "",
        surgicalHistoryRange: "0",
        familyHistoryRange: "0",
        HospitalizationRange: "0",
        HypertensionRange: "0",
        DMRange: "0",
        ThyroidRange: "0",
        HeartRange: "0",
        AsthmaRange: "0",
        AllergyRange: "0",
        CigaretteRange: "0",
        DrinkingAlcoholRange: "Unspecified",
        GravidaRange: "0",
        ParietyRange: "0",
        AbortionRange: "0",
        DeadRange: "0",
        LengthofPregnancyRange: "0",
        modeOfDeliveryRange: "0",
        aboutChildrenRange: "0",
        otherRange: "0",
        bloodTransfusionRange: "0",
      });
    }
  }, []);

  const handeSubmit = async (e) => {
    e.preventDefault();

    let types = [];
    let ranges = [];
    let notes = [];
    console.log(state);
    Object.entries(state).forEach(([key, value]) => {
      if (key.toString().toLowerCase().includes("range")) {
        ranges.push(value?.toString());
        console.log("ranges ", key, value);
      } else {
        console.log("value ", key, value);

        types.push(key?.toString());
        notes.push(value?.toString());
      }
    });

    let ids = [];
    setDisble(true);

    if (ex !== null && ex !== undefined && ex.length > 0) {
      let ids = [];
      ex.forEach((e) => {
        ids.push(e.id);
      });

      await updateMedicalHistory(types, ranges, notes, patient_id, ids);
      setDisble(false);
    } else {
      await saveMedicalHistory(types, ranges, notes, patient_id);
      setDisble(false);
    }
  };

  return (
    <div>
      <form onSubmit={handeSubmit}>
        <section>
          <div className="input-group input-group-lg">
            <div className="w-100">
              <label htmlFor="surgicalHistory">Surgical History</label>
              <textarea
                placeholder="surgicalHistory"
                value={state?.surgicalHistory}
                onChange={(e) => {
                  setState({ ...state, surgicalHistory: e.target.value });
                }}
                className={`form-control shadow-none  ml-1 mr-1`}
              />
            </div>
          </div>
          <div className="input-group input-group-lg mt-2">
            <div className="w-100">
              <label htmlFor="familyHistory">Family History</label>
              <textarea
                placeholder="familyHistory"
                value={state?.familyHistory}
                onChange={(e) => {
                  setState({ ...state, familyHistory: e.target.value });
                }}
                className={`form-control shadow-none  ml-1 mr-1`}
              />
            </div>
          </div>
          <div className="input-group input-group-lg mt-2">
            <div className="w-100">
              <label htmlFor="Hospitalization">Hospitalization</label>
              <textarea
                placeholder="Hospitalization"
                value={state?.Hospitalization}
                onChange={(e) => {
                  setState({ ...state, Hospitalization: e.target.value });
                }}
                className={`form-control shadow-none  ml-1 mr-1`}
              />
            </div>
          </div>
          <div className="input-group input-group-lg mt-2">
            <div className="w-100">
              <label htmlFor="bloodTransfusion">blood transfusion</label>
              <textarea
                placeholder="blood transfusion"
                value={state?.bloodTransfusion}
                onChange={(e) => {
                  setState({ ...state, bloodTransfusion: e.target.value });
                }}
                className={`form-control shadow-none  ml-1 mr-1`}
              />
            </div>
          </div>
        </section>
        {/*top history*/}
        <section className="mt-2 rounded-lg">
          <table className="table table-bordered w-100  table-responsive">
            <thead className="bg-info">
              <tr>
                <th className="w-25">Chronic Disease</th>
                <th style={sizeYes}>Yes/No</th>
                <th style={sizeDrug}>Drugs & Other Details</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Hypertension</td>
                <td className="text-center">
                  <Form>
                    <Form.Check
                      type="switch"
                      id="HypertensionSwitch"
                      checked={state?.HypertensionRange === "1"}
                      onChange={(e) => {
                        setState({
                          ...state,
                          HypertensionRange:
                            state?.HypertensionRange === "0" ? "1" : "0",
                        });
                      }}
                    />
                  </Form>
                </td>
                <td>
                  <textarea
                    placeholder="Hypertension"
                    value={state?.Hypertension}
                    onChange={(e) => {
                      setState({ ...state, Hypertension: e.target.value });
                    }}
                    className={`form-control shadow-none  ml-1 mr-1`}
                  />
                </td>
              </tr>
              {/*1*/}
              <tr>
                <td>DM</td>
                <td className="text-center">
                  <Form>
                    <Form.Check
                      type="switch"
                      id="DMSwitch"
                      checked={state?.DMRange === "1"}
                      onChange={(e) => {
                        setState({
                          ...state,
                          DMRange: state?.DMRange === "0" ? "1" : "0",
                        });
                      }}
                    />
                  </Form>
                </td>
                <td>
                  <textarea
                    placeholder="DM"
                    value={state?.DM}
                    onChange={(e) => {
                      setState({ ...state, DM: e.target.value });
                    }}
                    className={`form-control shadow-none  ml-1 mr-1`}
                  />
                </td>
              </tr>
              {/*2*/}
              <tr>
                <td>Thyroid</td>
                <td className="text-center">
                  <Form>
                    <Form.Check
                      type="switch"
                      id="ThyroidSwitch"
                      checked={state?.ThyroidRange === "1"}
                      onChange={(e) => {
                        setState({
                          ...state,
                          ThyroidRange: state?.ThyroidRange === "0" ? "1" : "0",
                        });
                      }}
                    />
                  </Form>
                </td>
                <td>
                  <textarea
                    placeholder="Thyroid"
                    value={state?.Thyroid}
                    onChange={(e) => {
                      setState({ ...state, Thyroid: e.target.value });
                    }}
                    className={`form-control shadow-none  ml-1 mr-1`}
                  />
                </td>
              </tr>
              {/*3*/}
              <tr>
                <td>Heart</td>
                <td className="text-center">
                  <Form>
                    <Form.Check
                      type="switch"
                      id="HeartSwitch"
                      checked={state?.HeartRange === "1"}
                      onChange={(e) => {
                        setState({
                          ...state,
                          HeartRange: state?.HeartRange === "0" ? "1" : "0",
                        });
                      }}
                    />
                  </Form>
                </td>
                <td>
                  <textarea
                    placeholder="Heart"
                    value={state?.Heart}
                    onChange={(e) => {
                      setState({ ...state, Heart: e.target.value });
                    }}
                    className={`form-control shadow-none  ml-1 mr-1`}
                  />
                </td>
              </tr>
              {/*4*/}
              <tr>
                <td>Asthma</td>
                <td className="text-center">
                  <Form>
                    <Form.Check
                      type="switch"
                      id="AsthmaSwitch"
                      checked={state?.AsthmaRange === "1"}
                      onChange={(e) => {
                        setState({
                          ...state,
                          AsthmaRange: state?.AsthmaRange === "0" ? "1" : "0",
                        });
                      }}
                    />
                  </Form>
                </td>
                <td>
                  <textarea
                    placeholder="Asthma"
                    value={state?.Asthma}
                    onChange={(e) => {
                      setState({ ...state, Asthma: e.target.value });
                    }}
                    className={`form-control shadow-none  ml-1 mr-1`}
                  />
                </td>
              </tr>
              {/*5*/}
              <tr>
                <td>Allergy</td>
                <td className="text-center">
                  <Form>
                    <Form.Check
                      type="switch"
                      id="AllergySwitch"
                      checked={state?.AllergyRange === "1"}
                      onChange={(e) => {
                        setState({
                          ...state,
                          AllergyRange: state?.AllergyRange === "0" ? "1" : "0",
                        });
                      }}
                    />
                  </Form>
                </td>
                <td>
                  <textarea
                    placeholder="Allergy"
                    value={state?.Allergy}
                    onChange={(e) => {
                      setState({ ...state, Allergy: e.target.value });
                    }}
                    className={`form-control shadow-none  ml-1 mr-1`}
                  />
                </td>
              </tr>
              {/*6*/}
              <tr>
                <td>Cigarette</td>
                <td className="text-center">
                  <Form>
                    <Form.Check
                      type="switch"
                      id="CigaretteSwitch"
                      checked={state?.CigaretteRange === "1"}
                      onChange={(e) => {
                        setState({
                          ...state,
                          CigaretteRange:
                            state?.CigaretteRange === "0" ? "1" : "0",
                        });
                      }}
                    />
                  </Form>
                </td>
                <td>
                  <textarea
                    placeholder="Cigarette"
                    value={state?.Cigarette}
                    onChange={(e) => {
                      setState({ ...state, Cigarette: e.target.value });
                    }}
                    className={`form-control shadow-none  ml-1 mr-1`}
                  />
                </td>
              </tr>
              {/*7*/}
              <tr>
                <td>Drinking Alcohol</td>
                <td className="text-center">
                  <Form.Control
                    as="select"
                    value={state?.DrinkingAlcoholRange}
                    onChange={(e) => {
                      setState({
                        ...state,
                        DrinkingAlcoholRange: e.target.value,
                      });
                    }}
                  >
                    <option>Mild</option>
                    <option>Sever</option>
                    <option>Unspecified</option>
                  </Form.Control>
                </td>
                <td>
                  <textarea
                    placeholder="DrinkingAlcohol"
                    value={state?.DrinkingAlcohol}
                    onChange={(e) => {
                      setState({
                        ...state,
                        DrinkingAlcohol: e.target.value,
                      });
                    }}
                    className={`form-control shadow-none  ml-1 mr-1`}
                  />
                </td>
              </tr>
              {/*8*/}
              <tr>
                <td colSpan={3}>
                  <h3>Obstetrics</h3>
                </td>
              </tr>
              <tr>
                <td>Gravida</td>
                <td className="text-center">
                  <Form>
                    <Form.Group controlId="exampleForm.ControlSelect1">
                      <Form.Control
                        as="select"
                        value={state?.GravidaRange}
                        onChange={(e) => {
                          setState({ ...state, GravidaRange: e.target.value });
                        }}
                      >
                        {[
                          0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15,
                        ].map((value) => (
                          <option>{value}</option>
                        ))}
                      </Form.Control>
                    </Form.Group>
                  </Form>
                </td>
                <td>
                  <textarea
                    placeholder="Gravida"
                    value={state?.Gravida}
                    onChange={(e) => {
                      setState({ ...state, Gravida: e.target.value });
                    }}
                    className={`form-control shadow-none  ml-1 mr-1`}
                  />
                </td>
              </tr>
              {/*9*/}
              <tr>
                <td>Pariety</td>
                <td className="text-center">
                  <Form>
                    <Form.Group
                      controlId="exampleForm.ControlSelect1"
                      value={state?.ParietyRange}
                      onChange={(e) => {
                        setState({ ...state, ParietyRange: e.target.value });
                      }}
                    >
                      <Form.Control as="select">
                        {[
                          0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15,
                        ].map((value) => (
                          <option>{value}</option>
                        ))}
                      </Form.Control>
                    </Form.Group>
                  </Form>
                </td>
                <td>
                  <textarea
                    placeholder="Pariety"
                    value={state?.Pariety}
                    onChange={(e) => {
                      setState({ ...state, Pariety: e.target.value });
                    }}
                    className={`form-control shadow-none  ml-1 mr-1`}
                  />
                </td>
              </tr>
              {/*10*/}
              <tr>
                <td>Abortion</td>
                <td className="text-center">
                  <Form>
                    <Form.Group controlId="exampleForm.ControlSelect1">
                      <Form.Control
                        as="select"
                        value={state?.AbortionRange}
                        onChange={(e) => {
                          setState({
                            ...state,
                            AbortionRange: e.target.value,
                          });
                        }}
                      >
                        {[
                          0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15,
                        ].map((value) => (
                          <option>{value}</option>
                        ))}
                      </Form.Control>
                    </Form.Group>
                  </Form>
                </td>
                <td>
                  <textarea
                    placeholder="Abortion"
                    value={state?.Abortion}
                    onChange={(e) => {
                      setState({ ...state, Abortion: e.target.value });
                    }}
                    className={`form-control shadow-none  ml-1 mr-1`}
                  />
                </td>
              </tr>
              {/*11*/}
              <tr>
                <td>Dead</td>
                <td className="text-center">
                  <Form>
                    <Form.Group controlId="exampleForm.ControlSelect1">
                      <Form.Control
                        as="select"
                        value={state?.DeadRange}
                        onChange={(e) => {
                          setState({ ...state, DeadRange: e.target.value });
                        }}
                      >
                        {[
                          0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15,
                        ].map((value) => (
                          <option>{value}</option>
                        ))}
                      </Form.Control>
                    </Form.Group>
                  </Form>
                </td>
                <td>
                  <textarea
                    placeholder="Dead"
                    value={state?.Dead}
                    onChange={(e) => {
                      setState({ ...state, Dead: e.target.value });
                    }}
                    className={`form-control shadow-none  ml-1 mr-1`}
                  />
                </td>
              </tr>
              {/*12*/}
              <tr>
                <td>Length of pregnancy</td>
                <td className="text-center">
                  <Form>
                    <Form.Check
                      type="switch"
                      id="LengthOfPregnancySwitch"
                      checked={state?.LengthofPregnancyRange === "1"}
                      onChange={(e) => {
                        setState({
                          ...state,
                          LengthofPregnancyRange:
                            state?.LengthofPregnancyRange === "0" ? "1" : "0",
                        });
                      }}
                    />
                  </Form>
                </td>
                <td>
                  <textarea
                    placeholder="LengthofPregnancy"
                    value={state?.LengthofPregnancy}
                    onChange={(e) => {
                      setState({ ...state, LengthofPregnancy: e.target.value });
                    }}
                    className={`form-control shadow-none  ml-1 mr-1`}
                  />
                </td>
              </tr>
              {/*13*/}
              <tr>
                <td>mode of delivery</td>
                <td className="text-center">
                  <Form>
                    <Form.Check
                      type="switch"
                      id="modeOfDeliverySwitch"
                      checked={state?.modeOfDeliveryRange === "1"}
                      onChange={(e) => {
                        setState({
                          ...state,
                          modeOfDeliveryRange:
                            state?.modeOfDeliveryRange === "0" ? "1" : "0",
                        });
                      }}
                    />
                  </Form>
                </td>
                <td>
                  <textarea
                    placeholder="modeOfDelivery"
                    value={state?.modeOfDelivery}
                    onChange={(e) => {
                      setState({ ...state, modeOfDelivery: e.target.value });
                    }}
                    className={`form-control shadow-none  ml-1 mr-1`}
                  />
                </td>
              </tr>
              {/*14*/}
              <tr>
                <td>about children</td>
                <td className="text-center">
                  <Form>
                    <Form.Check
                      type="switch"
                      checked={state?.aboutChildrenRange === "1"}
                      id="abouthChildrenSwitch"
                      onChange={(e) => {
                        setState({
                          ...state,
                          aboutChildrenRange:
                            state?.aboutChildrenRange === "0" ? "1" : "0",
                        });
                      }}
                    />
                  </Form>
                </td>
                <td>
                  <textarea
                    placeholder="aboutChildren"
                    value={state?.aboutChildren}
                    onChange={(e) => {
                      setState({ ...state, aboutChildren: e.target.value });
                    }}
                    className={`form-control shadow-none  ml-1 mr-1`}
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </section>
        {/*table history*/}
        <section className="mt-2">
          <div className="mt-2">
            {/*bithday wight*/}

            <hr />
            <div className="input-group input-group-lg mt-2">
              <div className="w-100">
                <label htmlFor="other">other</label>
                <textarea
                  placeholder="other"
                  value={state?.other}
                  onChange={(e) => {
                    setState({ ...state, other: e.target.value });
                  }}
                  className={`form-control shadow-none  ml-1 mr-1`}
                />
              </div>
            </div>
          </div>
        </section>

        <button
          className="btn btn-success btn-lg mt-3 w-100"
          type="submit"
          disabled={disable}
        >
          Submit
        </button>
      </form>{" "}
    </div>
  );
};

export default HestoryVisit;
