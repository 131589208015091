import { Editor, EditorState } from "draft-js";
import React, { Component } from "react";
import "../printStyle.css";
import PersonalInfoPrint from "../printTremnats/personalInFo";

class ReportPage extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    return (
      <table >
        <thead>
          <PersonalInfoPrint patinet={this.props.patinet} />
        </thead>
        <tbody>
          {" "}
          <div
            className="text-justify"
            style={{ "font-size": "25px", width: "16cm" }}
          >
            <Editor
              editorState={EditorState.createWithContent(this.props.state)}
              defaultEditorState={{
                blocks: [
                  {
                    key: "afse5",
                    text: "sdfds dfsdfsd sdfsdfs sdfs",
                    type: "unstyled",
                    depth: 0,

                    entityRanges: [],
                    data: {},
                  },
                ],
                entityMap: {},
              }}
              toolbar={{
                options: ["inline", "history"],
                inline: { inDropdown: false },
              }}
            />
          </div>
        </tbody>
      </table>
    );
  }
}

export default ReportPage;
