 import {
  ADD_DOCTOR_REQUEST,
  ADD_DOCTOR_REQUEST_SUCCESS,
  ADD_DOCTOR_REQUEST_FAIL,
  GET_DOCTOR_REQUEST,
  GET_DOCTOR_REQUEST_SUCCESS,
  GET_DOCTOR_REQUEST_FAIL,
  DELETE_DOCTOR_REQUEST,
  DELETE_DOCTOR_REQUEST_SUCCESS,
  DELETE_DOCTOR_REQUEST_FAIL,
  UPDATE_DOCTOR_REQUEST,
  UPDATE_DOCTOR_REQUEST_SUCCESS,
  UPDATE_DOCTOR_REQUEST_FAIL,
} from '../constants/referaldoctorConstants';
import { getToken } from '../reducers/userReducers';
import swal from "sweetalert";
import api from '../constants/api';

export const addRefDoctor = (token, name, phone ,reset) => async dispatch => {
  try {
    dispatch({
      type: ADD_DOCTOR_REQUEST,
    });

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer  ${getToken()}`,
      },
    };

    const { data } = await api.post(
      'clinic-referral-doctors/create',
      { name, phone },
      config
    );
    reset(); swal("Good job!", "Doctor was added successfully", "success");
    dispatch({
      type: ADD_DOCTOR_REQUEST_SUCCESS,
      payload: data,
    });
  } catch (error) { swal("Failed!", "Failed to add Doctor", "error");
    dispatch({
      type: ADD_DOCTOR_REQUEST_FAIL,
    });
  }
};

export const getRefDoctor = token => async dispatch => {
  try {
    dispatch({
      type: GET_DOCTOR_REQUEST,
    });

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer  ${getToken()}`,
      },
    };

    const { data } = await api.get(
      'clinic-referral-doctors',
      config
    );

    dispatch({
      type: GET_DOCTOR_REQUEST_SUCCESS,
      payload: data,
    });
  } catch (error) {

    dispatch({
      type: GET_DOCTOR_REQUEST_FAIL,
    });
  }
};

export const deleteRefDoctor = (token, id) => async dispatch => {
  try {
    dispatch({
      type: DELETE_DOCTOR_REQUEST,
    });

    const { data } = await api.delete(
      `clinic-referral-doctors/delete/${id}`,

      {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          Authorization: `Bearer  ${getToken()}`,
        },
      }
    );     swal("Good job!", "Doctor Tes was deleted successfully", "success");

    dispatch({
      type: DELETE_DOCTOR_REQUEST_SUCCESS,
      payload: data,
    });
  } catch (error) {    swal("Failed!", "Failed to delete Doctor", "error");

    dispatch({
      type: DELETE_DOCTOR_REQUEST_FAIL,
    });
  }
};

export const updateRefDocto = (token, id, name, phone,reset) => async dispatch => {
  try {
    dispatch({
      type: UPDATE_DOCTOR_REQUEST,
    });

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer  ${getToken()}`,
      },
    };

    const { data } = await api.put(
      `clinic-referral-doctors/update/${id}`,
      { name, phone },
      config
    );
    reset();
     swal("Good job!", "Doctor was updated successfully", "success");
    dispatch({
      type: UPDATE_DOCTOR_REQUEST_SUCCESS,
      payload: data,
    });
  } catch (error) {
    swal("Failed!", "Failed to update Doctor", "error");
    dispatch({
      type: UPDATE_DOCTOR_REQUEST_FAIL,
    });
  }
};
